import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {
  classifyProductsByFileApi,
  classifyProductsByQueryApi,
} from "../../api";
import styles from "./styles.module.css";

const Home = () => {
  const [query, setQuery] = useState("");
  const [previousData, setPreviousData] = useState([]);
  //   const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileRef = useRef(null);
  const wrapperEndRef = useRef(null);

  useEffect(() => {
    wrapperEndRef.current?.scrollIntoView({ behaviour: "smooth" });
  }, [wrapperEndRef, previousData]);

  const downloadData = async (dataArray) => {
    try {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const ws = XLSX.utils.json_to_sheet(dataArray);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "Report.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const sendQuery = async () => {
    try {
      setLoading(true);
      const response = await classifyProductsByQueryApi({ query });
      if (response.status === 200) {
        if (Array.isArray(response.data)) {
          setPreviousData((prev) => [
            ...prev,
            {
              type: "query",
              responseStatus: "success",
              inputData: query,
              responseData: response.data,
            },
          ]);
        } else if (typeof response.data === "object") {
          setPreviousData((prev) => [
            ...prev,
            {
              type: "query",
              responseStatus: "success",
              inputData: query,
              responseData: [response.data],
            },
          ]);
        } else if (typeof response.data === "string") {
          setPreviousData((prev) => [
            ...prev,
            {
              type: "query",
              responseStatus: "error",
              inputData: query,
              responseData: response.data,
            },
          ]);
        }
        setQuery("");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const sendFile = async (e) => {
    try {
      let previousProductsData = [...previousData];
      let newProductsData = [];
      setLoading(true);
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        // let formData = new FormData();
        // formData.append("file", file);
        // const response = await classifyProductsByFileApi(formData);
        // if (response.status === 200) {
        //   const resultFile = new Blob([response.data], {
        //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        //   });

        //   const reader = new FileReader();
        //   reader.onload = (e) => {
        //     const data = new Uint8Array(e.target.result);
        //     const workbook = XLSX.read(data, { type: "array" });
        //     const firstSheetName = workbook.SheetNames[0];
        //     const worksheet = workbook.Sheets[firstSheetName];
        //     const json = XLSX.utils.sheet_to_json(worksheet);
        //     setPreviousData((prev) => [
        //       ...prev,
        //       {
        //         type: "file",
        //         responseStatus: "success",
        //         inputData: "query",
        //         responseData: json,
        //       },
        //     ]);
        //   };
        //   reader.readAsArrayBuffer(resultFile);
        //   setQuery("");
        // }
        if (file) {
          const reader = new FileReader();
          reader.onload = async (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            let queryArray = [];
            for (let i = 0; i < jsonData.length; i++) {
              queryArray.push(jsonData[i]["Product Name"]);
              if (queryArray.length === 40 || i === jsonData.length - 1) {
                const response = await classifyProductsByQueryApi({
                  query: queryArray.join("\n"),
                });
                if (response.status === 200) {
                  if (Array.isArray(response.data)) {
                    newProductsData.push(...response.data);
                    setPreviousData([
                      ...previousProductsData,
                      {
                        type: "query",
                        responseStatus: "success",
                        inputData: jsonData
                          .slice(0, i + 1)
                          .map((a) => a["Product Name"])
                          .slice("\n"),
                        responseData: newProductsData,
                      },
                    ]);
                  } else if (typeof response.data === "object") {
                    newProductsData.push(response.data);
                    setPreviousData([
                      ...previousProductsData,
                      {
                        type: "query",
                        responseStatus: "success",
                        inputData: jsonData
                          .slice(0, i + 1)
                          .map((a) => a["Product Name"])
                          .slice("\n"),
                        responseData: newProductsData,
                      },
                    ]);
                  } else if (typeof response.data === "string") {
                    console.log(response.data);
                    newProductsData.push(response.data);
                    setPreviousData([
                      ...previousProductsData,
                      {
                        type: "query",
                        responseStatus: "success",
                        inputData: jsonData
                          .slice(0, i + 1)
                          .map((a) => a["Product Name"])
                          .slice("\n"),
                        responseData: newProductsData,
                      },
                    ]);
                  }
                  console.log(newProductsData);
                  setQuery("");
                  queryArray = [];
                }
              }
              if (i === jsonData.length - 1) {
                setLoading(false);
              }
            }
          };
          reader.readAsArrayBuffer(file);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  return (
    <div className={styles.containerStyle}>
      <div className={styles.contentWrapperStyle}>
        {previousData.map((data, index) => (
          <div key={index} className={styles.positionStyle}>
            {data.responseStatus === "success" && (
              <React.Fragment>
                <table className={styles.tableStyle}>
                  <thead>
                    <th className={styles.thStyle}>S.no</th>
                    <th className={styles.thStyle}>Product</th>
                    <th className={styles.thStyle}>Category</th>
                    <th className={styles.thStyle}>Type</th>
                    <th className={styles.thStyle}>CN Number</th>
                  </thead>
                  <tbody>
                    {data?.responseData?.map((subData, index) => (
                      <tr key={index}>
                        <td className={styles.tdStyle}>{index + 1}</td>
                        <td className={styles.tdStyle}>
                          {subData?.product || "N.A"}
                        </td>
                        {"error" in subData ? (
                          <td
                            className={styles.tdStyle}
                            style={{ color: "red" }}
                            colSpan={3}
                          >
                            {subData.error}
                          </td>
                        ) : (
                          <React.Fragment>
                            <td className={styles.tdStyle}>
                              {subData?.classification || "N.A"}
                            </td>
                            <td className={styles.tdStyle}>
                              {subData?.type || "N.A"}
                            </td>
                            <td className={styles.tdStyle}>
                              {subData?.cnNumber || "N.A"}
                            </td>
                          </React.Fragment>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p
                  className={styles.linkStyle}
                  onClick={() => downloadData(data.responseData)}
                >
                  Download report
                </p>
              </React.Fragment>
            )}
            {data.responseStatus === "error" && (
              <p className={styles.errorTextStyle}>{data.responseData}</p>
            )}
          </div>
        ))}
        {loading && (
          <div className={styles.positionStyle}>
            Analysing data...
            <div className={styles.loaderStyle} />
          </div>
        )}
        <div ref={wrapperEndRef} />
      </div>
      <div className={styles.inputWrapperStyle}>
        <button
          type="button"
          className={styles.btnWrapperStyle}
          onClick={() => {
            fileRef.current.value = "";
            fileRef.current?.click();
          }}
          disabled={loading}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"></path>
          </svg>
        </button>
        <input
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          ref={fileRef}
          type="file"
          style={{ display: "none" }}
          name="file"
          onChange={sendFile}
        />
        <textarea
          className={styles.textAreaStyle}
          name="query"
          placeholder="Enter the product names"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <button
          type="button"
          className={styles.btnWrapperStyle}
          onClick={sendQuery}
          disabled={loading}
        >
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m22 2-7 20-4-9-9-4Z"></path>
            <path d="M22 2 11 13"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Home;
