import axios from "./axios";

const endPoints = {
  classifyProductsByQuery: "/classify",
  classifyProductsByFile: "/classify-by-file",
};

export const classifyProductsByQueryApi = async (data) =>
  Promise.resolve(axios.post(endPoints.classifyProductsByQuery, data));

export const classifyProductsByFileApi = async (data) =>
  Promise.resolve(axios.post(endPoints.classifyProductsByFile, data));
